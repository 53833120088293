<template>
  <c-box
    :h="style.height"
    :bg="style.backgroundColor"
    :border="`1px solid ${style.borderColor}`"
    :color="style.color"
    :font-size="style.fontSize"
    d="flex"
    justify-content="center"
    align-items="center"
    rounded="1000px"
    font-weight="500"
    white-space="nowrap"
    px="8px"
    :py="['6px', '8px']"
  >
    {{ label }}
    <c-box
      v-if="!hasRemainingQuota"
      ml="8px"
      :h="['16px','20px']"
      :w="['16px','20px']"
    >
      <inline-svg
        :src="iconCircleWarn"
        min-height="100%"
        height="100%"
        min-width="100%"
        width="100%"
        :fill="style.color"
      />
    </c-box>
  </c-box>
</template>

<script>
import iconCircleWarn from '@/assets/icons/icon-circle-warn.svg'
import { mapState } from 'vuex'

export default {
  props: {
    remainingQuota: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      iconCircleWarn,
    }
  },
  computed: {
    ...mapState({
      user: (s) => s.auth.user,
    }),
    isClient() {
      if (this.user && this.user?.role == 'client') return true
      return false
    },
    hasRemainingQuota() {
      return this.remainingQuota > 0
    },
    style() {
      return {
        backgroundColor: this.hasRemainingQuota ? '#C7F9E3' : '#FCDED3',
        borderColor: this.hasRemainingQuota ? '#008C81' : '#D32737',
        color: this.hasRemainingQuota ? '#008C81' : '#D32737',
        height: this.hasRemainingQuota ? 'fit-content' : 'fit-content',
        fontSize: ['12px', '16px'],
      }
    },
    label() {
      return this.hasRemainingQuota ? (this.isClient ? 'Available' : `Sisa kuota: ${this.remainingQuota}`) : 'Full'
    },
  },
}
</script>
