var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "h": _vm.style.height,
      "bg": _vm.style.backgroundColor,
      "border": "1px solid ".concat(_vm.style.borderColor),
      "color": _vm.style.color,
      "font-size": _vm.style.fontSize,
      "d": "flex",
      "justify-content": "center",
      "align-items": "center",
      "rounded": "1000px",
      "font-weight": "500",
      "white-space": "nowrap",
      "px": "8px",
      "py": ['6px', '8px']
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " "), !_vm.hasRemainingQuota ? _c('c-box', {
    attrs: {
      "ml": "8px",
      "h": ['16px', '20px'],
      "w": ['16px', '20px']
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": _vm.iconCircleWarn,
      "min-height": "100%",
      "height": "100%",
      "min-width": "100%",
      "width": "100%",
      "fill": _vm.style.color
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }