var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "display": "flex",
      "align-items": "center"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-star.svg'),
      "alt": "icon",
      "display": "inline-block",
      "vertical-align": "middle",
      "margin-right": "0px",
      "height": ['15px', '16px'],
      "width": ['15px', '16px']
    }
  }), _c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['12px', '14px'],
      "color": "gray.900"
    }
  }, [_vm._v(" " + _vm._s(_vm.rating) + "/5 ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }