<template>
  <c-box
    display="flex"
    align-items="center"
  >
    <c-image
      :src="require('@/assets/icon-star.svg')"
      alt="icon"
      display="inline-block"
      vertical-align="middle"
      margin-right="0px"
      :height="['15px', '16px']"
      :width="['15px', '16px']"
    />
    <c-text
      font-family="Roboto"
      :font-size="['12px', '14px']"
      color="gray.900"
    >
      {{ rating }}/5
    </c-text>
  </c-box>
</template>

<script>
export default {
  name: 'RatingComp',
  props: {
    rating: {
      type: [String, Number],
      default: 0,
    },
  },
}
</script>
